/* eslint-disable */
// @ts-ignore
import { defineAsyncComponent } from 'vue'
export const registerComponents = ( app:any ) => {
  app.component("NavBar", defineAsyncComponent(() => import("@/components/NavBar.vue")))
  app.component("TopSection", defineAsyncComponent(() => import("@/components/TopSection.vue")))
  app.component("AboutUs", defineAsyncComponent(() => import("@/components/AboutUs.vue")))
  app.component("OurTeam", defineAsyncComponent(() => import("@/components/OurTeam.vue")))
  app.component("ContactUs", defineAsyncComponent(() => import("@/components/ContactUs.vue")))
  app.component("Footer", defineAsyncComponent(() => import("@/components/Footer.vue")))
}
